<template>
    <layout-modal
        v-if="active"
        ref="modal"
    >
        <template #title>
            <span
                class="text-xl uppercase leading-tight"
                v-html="titleHtml"
            />
        </template>

        <template #default>
            <img
                :src="qrCodeUrl"
                :alt="$t('portal.rewards.qr_code')"
                class="max-w-64 h-auto mx-auto aspect-square my-8"
            />

            <p class="mt-4 mb-1">{{ $t("portal.rewards.value_now") }}: <span v-html="formatValueNow" /></p>

            <p>{{ $t("portal.rewards.valid_until") }}: <span v-text="validTo" /></p>
        </template>
    </layout-modal>
</template>

<script setup lang="ts">
import type { Reward } from "~/types";
import type { LayoutModal } from "#components";

const props = defineProps<{
    reward: Reward;
    type: "voucher" | "coupon";
    active: boolean;
}>();

const { t } = useI18n();
const i18nLocale = useI18n();
const dayjs = useDayjs();
const baseUrl = useBaseUrl();

const modal = ref<InstanceType<typeof LayoutModal> | null>(null);
const currency = "&euro;";

const locale = computed<string>(() => {
    return i18nLocale.locale.value;
});

const titleHtml = computed(() => {
    let rewardTitle;
    const type = props.reward.benefit_type ?? "";

    if (props.type === "voucher") {
        rewardTitle = t("portal.rewards.type.voucher") + "<br>" + type;
    } else {
        rewardTitle = type;
    }

    return rewardTitle.trim();
});

const formatValueNow = computed(() => {
    if (!props.reward.value_now && props.reward.value_now !== 0) {
        return t("portal.rewards.value_unknown");
    }

    const val = parseFloat(props.reward.value_now + "");

    return (
        val.toLocaleString(locale.value, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }) +
        " " +
        currency
    );
});

const validTo = computed(() => {
    if (!props.reward.valid_to) {
        return t("portal.rewards.valid_until_unknown");
    }

    return dayjs.utc(props.reward.valid_to).format("DD.MM.YYYY");
});

const qrCodeUrl = computed(() => {
    return `${baseUrl}api/rewards/download/${props.reward.code}/qr-code`;
});

defineExpose({
    open: () => {
        modal.value?.open();
    },
});
</script>
